<template>
  <div>
    <v-tabs v-model="selectedTab" grow class="elevation-1">
      <v-tab v-for="tab in tabs" :key="tab.name" class="accent--text">
        {{ tab.text }}
      </v-tab>
      <v-tabs-slider color="accent"></v-tabs-slider>

      <v-tabs-items
        v-model="selectedTab"
        touchless
        class="elevation-0 transparent"
      >
        <v-tab-item v-for="tab in tabs" :key="tab.name">
          <template v-if="tab.name === 'businesses'">
            <v-data-table
              :headers="businessHeaders"
              :items="businessCommission"
              :items-per-page="25"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, key) in items" :key="key">
                    <td class="word-no-wrap" v-if="item.business">
                      <router-link
                        :to="{
                          name: 'business',
                          params: { id: item.business.uuid }
                        }"
                      >
                        {{ item.business.display_name }}
                      </router-link>
                    </td>
                    <td v-else>&nbsp;</td>

                    <td>{{ item.new | local_numbers }}</td>
                    <td>{{ item.approved | local_numbers }}</td>
                    <td>{{ item.invoiced | local_numbers }}</td>
                    <td>{{ item.pending_payout | local_numbers }}</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </template>
          <template v-else-if="tab.name === 'influencers'">
            <v-data-table
              :headers="influencerHeaders"
              :items="influencerCommission"
              :items-per-page="25"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, key) in items" :key="key">
                    <td class="word-no-wrap" v-if="item.influencer">
                      <router-link
                        :to="{
                          name: 'influencer',
                          params: { id: item.influencer.uuid }
                        }"
                      >
                        {{ item.influencer.user.first_name }}
                        {{ item.influencer.user.last_name }}
                      </router-link>
                    </td>
                    <td v-else>&nbsp;</td>
                    <td>{{ item.new | local_numbers }}</td>
                    <td>{{ item.approved | local_numbers }}</td>
                    <td>{{ item.invoiced | local_numbers }}</td>
                    <td>{{ item.pending_payout | local_numbers }}</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import local_numbers from "@/helpers/filters/local_numbers";

export default {
  filters: { local_numbers },
  data: () => ({
    selectedTab: null,
    businessCommission: [],
    influencerCommission: [],
    tabs: [
      { name: "influencers", text: "Influencers" },
      { name: "businesses", text: "Brands" }
    ],
    businessHeaders: [
      { text: "Company", value: "business.display_name" },
      { text: "New", value: "new" },
      { text: "Approved", value: "approved" },
      { text: "Invoiced", value: "invoiced" },
      { text: "Pending payout", value: "pending_payout" }
    ],
    influencerHeaders: [
      { text: "Influencer", value: "influencer.user.first_name" },
      { text: "New", value: "new" },
      { text: "Approved", value: "approved" },
      { text: "Invoiced", value: "invoiced" },
      { text: "Pending payout", value: "pending_payout" }
    ]
  }),
  methods: {
    ...mapActions("loading", ["setPageLoading"]),
    ...mapActions("core", ["getComissionStatus"]),
    getBusinessCommission() {
      this.getComissionStatus({ business: true }).then(
        response => {
          this.businessCommission = response.data
        },
        error => {
          console.log(error);
        }
      );
    },
    getInfluencerCommission() {
      this.getComissionStatus({ influencer: true }).then(
        response => {
          this.influencerCommission = response.data
        },
        error => {
          console.log(error);
        }
      );
    }
  },
  created() {
    this.getBusinessCommission();
    this.getInfluencerCommission();
  }
};
</script>
